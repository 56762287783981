import { MOCK_DATA } from "./mock_data";
// Set the API base URL by uncommenting the appropriate mode

// Development Mode (DEV)
// const API_BASE_ENDPOINT_URL = "https://staging.uofthacks.com";

// Production Mode (PROD)
// const API_BASE_ENDPOINT_URL = "https://api.uofthacks.com";

// Frontend Only Mode (FE)
const API_BASE_ENDPOINT_URL = ""; // No backend interaction

console.log(`API Base URL: ${API_BASE_ENDPOINT_URL}`); // For debugging

export { API_BASE_ENDPOINT_URL };

//_______________________________________________________________________________________________BELOW IS IMPLEMENTATION USING .env file instead of commenting
// // Define MODE variable
// const MODE = process.env.REACT_APP_MODE || "PROD"; // Default to PROD mode

// // Dynamically set the API base URL based on the mode using .envfile
// let API_BASE_ENDPOINT_URL: string;

// switch (MODE) {
//   case "DEV":
//     API_BASE_ENDPOINT_URL = "https://staging.uofthacks.com";
//     break;
//   case "FE":
//     API_BASE_ENDPOINT_URL = ""; // No backend interaction for FE
//     break;
//   case "PROD":
//   default:
//     API_BASE_ENDPOINT_URL = "https://api.uofthacks.com";
//     break;
// }
// console.log(`API Mode: ${MODE}`); // Log mode for debugging
//__________________________________________________________________________________________________________

export const fetchJudgeList = async (pin: any) => {
  if (!API_BASE_ENDPOINT_URL) {
    // DEV mode validation
    const VALID_DEV_PIN = "90210"; // Set your desired PIN

    if (pin !== VALID_DEV_PIN) {
      return Promise.reject(new Error("Invalid PIN"));
    }

    // FE mode: Return mock data
    return Promise.resolve({
      judges: ["Mock Judge 1", "Mock Judge 2", "Mock Judge 3"], // List of judge names
      challenge: "Mock Sponsor Group", // Challenge name
    });
  }

  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/judgelist`);
    url.searchParams.append("company_id", pin);

    const response = await fetch(url.toString());

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Bad request");
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching judge list:", error);
    throw error;
  }
};

export const registerJudge = async (pin: string, judgeName: string) => {
  try {
    const url = `${API_BASE_ENDPOINT_URL}/12/judge/register`;

    const requestBody = {
      company_id: pin,
      judges_name: judgeName,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(url, requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Bad request");
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error registering judge:", error);
    throw error;
  }
};

export const loginJudge = async (pin: string, judgeName: string) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/login`);

    const requestBody = {
      company_id: pin,
      judges_name: judgeName,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Bad request");
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error logging the judge in:", error);
    throw error;
  }
};

export const getProjects = async (authToken: any) => {
  if (!API_BASE_ENDPOINT_URL) {
    return Promise.resolve(MOCK_DATA);
  }

  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/projects?query=`);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      return {
        errorMessage: errorData.message,
      };
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};

export const getResults = async (authToken: any) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/results`);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      return {
        errorMessage: errorData.message,
      };
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching results:", error);
    throw error;
  }
};

export const markProj = async (
  authToken: any,
  projId: any,
  projMark: any,
  projNotes: any
) => {
  try {
    const url = new URL(`${API_BASE_ENDPOINT_URL}/12/judge/updatescore`);

    const requestBody = {
      proj_id: projId,
      proj_mark: projMark,
      proj_notes: projNotes,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(url.toString(), requestOptions);

    if (response.status === 400) {
      const errorData = await response.json();
      return {
        errorMessage: errorData.message,
      };
    }
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating project score:", error);
    throw error;
  }
};
