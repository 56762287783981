// mock_data.ts
export const MOCK_DATA = {
  judging_enabled: true,
  projects: [
    {
      proj_id: 1,
      proj_name: "Project Alpha",
      proj_mark: 90,
      proj_members: [
        { name: "Alice", email: "alice@example.com" },
        { name: "Bob", email: "bob@example.com" },
      ],
      proj_desc: "A fantastic project about Alpha.",
      proj_devpost: "https://devpost.com/project-alpha",
      proj_notes: [
        { author: "Mock Judge", note: "Great work!" },
        { author: "Other Judge", note: "Good job!" },
      ],
    },
    {
      proj_id: 2,
      proj_name: "Project Beta",
      proj_mark: 85,
      proj_members: [
        { name: "Charlie", email: "charlie@example.com" },
        { name: "Dave", email: "dave@example.com" },
      ],
      proj_desc: "A fantastic project about Beta.",
      proj_devpost: "https://devpost.com/project-beta",
      proj_notes: [
        { author: "Mock Judge", note: "Needs improvement in UI." },
        { author: "Other Judge", note: "Interesting approach." },
      ],
    },
    {
      proj_id: 3,
      proj_name: "Project Gamma",
      proj_mark: null,
      proj_members: [
        { name: "Eve", email: "eve@example.com" },
        { name: "Frank", email: "frank@example.com" },
      ],
      proj_desc: "A work-in-progress project about Gamma.",
      proj_devpost: "https://devpost.com/project-gamma",
      proj_notes: [],
    },
  ],
  results: [
    { id: 1, name: "Project Alpha", score: 90 },
    { id: 2, name: "Project Beta", score: 85 },
  ],
};
